import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { clearfix } from 'styles/clearfix'
import { column } from 'styles/column'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ShopLinks = ({ links, type }) => {
  return (
    <Container>
      {links.map((obj, key) => (
        <Box key={key} type={type}>
          <div className='link-box-background'>
            <GatsbyImage
              image={obj.image}
              objectFit='cover'
              objectPosition='50% 50%'
              alt=''
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className='link-box-content'>
            <Link to={obj.link} className='link-box-link'>
              <div className='link-box-container'>
                <div className='link-box-copy'>
                  <h3>{obj.title}</h3>
                  <hr />
                  <div className='link-box-learn-more'>
                    {obj.linkText}
                    <span className='right-arrow'>&rarr;</span>
                  </div>
                  <div className='link-box-description'>
                    <p>{obj.description}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Box>
      ))}
    </Container>
  )
}

ShopLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequried,
      image: PropTypes.string.isRequried,
      link: PropTypes.string.isRequried,
      linkText: PropTypes.string.isRequried,
      title: PropTypes.string.isRequried,
    })
  ).isRequired,
  type: PropTypes.oneOf(['stihl', 'john-deere']),
}

ShopLinks.defaultProps = {
  type: 'john-deere',
}

const Container = styled.div`
  @media (min-width: 600px) {
    ${clearfix}
  }
`

const Box = styled.div`
  height: 50vh;
  min-height: 200px;
  max-height: 250px;
  margin: 0 auto;
  position: relative;
  width: 100%;

  a {
    color: #fff;
    text-decoration: none;
  }

  .link-box-background {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .link-box-content {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .link-box-container {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: relative;
    width: 100%;

    .link-box-copy {
      bottom: 15%;
      position: absolute;
      width: 100%;
    }

    .link-box-description {
      height: 0;
      overflow: hidden;
    }

    .link-box-learn-more {
      display: block;
      padding: 5px 0 0;
      font-size: 1.1em;
      font-style: italic;
      text-align: center;

      .right-arrow {
        padding-left: 7px;
      }
    }

    h3 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0;
      max-width: none;
      padding: 0 10px;
      text-align: center;
      text-transform: none;
      width: 100%;
    }

    hr {
      background-color: ${props => (props.type === 'stihl' ? '#f5821f' : '#377539')};
      border: none;
      color: ${props => (props.type === 'stihl' ? '#f5821f' : '#377539')};
      height: 2px;
      margin: 5px auto;
      width: 100px;
    }

    p {
      margin: 0 auto;
      padding: 0 10px;
      text-align: center;
      width: 80%;
    }
  }

  @media (min-width: 600px) {
    ${column('1/2', 0)}
  }

  @media (min-width: 800px) {
    ${column('1/4', 0)}

    .link-box-container {
      .link-box-description {
        transition: 0.2s height;
      }

      .link-box-learn-more {
        display: none;
      }
    }

    .link-box-link {
      :hover,
      :focus {
        background-color: rgba(0, 0, 0, 0.6);

        .link-box-description {
          height: 100px;
        }
      }
    }
  }

  @media (min-width: 1000px) {
    .link-box-link {
      :hover,
      :focus {
        .link-box-description {
          height: 75px;
        }
      }
    }
  }
`

export default ShopLinks
