import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import { graphql } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import Promos from 'components/promos'
import TopFold from 'components/locations/location-top-fold'
import H2 from 'components/typography/h2'
import ShopLinks from 'components/shop-links'
import { GatsbyImage } from 'gatsby-plugin-image'
import isDefined from 'utils/is-defined'
import isEmptyString from 'utils/is-empty-string'

const LocationStihlTemplate = props => {
  const { breadcrumbs, data, images, meta, promos, shopLinks, title } = getInitialProps(props)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <TopFold
          address={data.address}
          breadcrumbs={breadcrumbs}
          city={data.city}
          directionsLink={data.directionsLink}
          specialHours={data.specialHours}
          hours={data.hours}
          images={images}
          locationNumber={data.locationNumber}
          partsPhoneNumber={data.showPartsPhoneNumber ? data.partsPhoneNumber : null}
          phoneNumber={data.phoneNumber}
          servicePhoneNumber={data.showServicePhoneNumber ? data.servicePhoneNumber : null}
          state={data.state}
          title={`STIHL dealer in ${title}`}
          zip={data.zip}
        />
      </Content>

      <Wrapper>
        <H2 textAlign='center'>{`Shop STIHL at Hutson in ${title}`}</H2>
      </Wrapper>

      <ShopLinks links={shopLinks} type='stihl' />

      <Content>
        {isDefined(data.stihlDescriptionHtml) && !isEmptyString(data.stihlDescriptionHtml) ? (
          <>
            <H2>About Hutson in {title}</H2>
            <div dangerouslySetInnerHTML={{ __html: data.stihlDescriptionHtml }} />
          </>
        ) : null}
        <Promos data={promos} type={`${title} STIHL`} />
      </Content>
    </Layout>
  )
}

const Wrapper = styled(Content)`
  padding: 0;
`

const _renderImg = item => {
  return (
    <div>
      <GatsbyImage image={item.original} alt={item.originalAlt} />
    </div>
  )
}

const _renderImgThumb = item => {
  return (
    <div>
      <GatsbyImage image={item.thumbnail} alt={item.thumbnailAlt} />
    </div>
  )
}

const getInitialProps = ({
  data: {
    allHutsonPromotion,
    sanityLocation: { images, imagesThumbnails, slug, title },
    sanityLocation,
  },
  data,
  location: { pathname },
}) => {
  // Get images for carousel
  const carouselImages = images.map((image, i) => {
    return {
      original: image.asset.gatsbyImageData,
      thumbnail: imagesThumbnails[i].asset.gatsbyImageData,
      originalAlt: `Photo ${i} of the ${title} Hutson location`,
      thumbnailAlt: `Photo ${i} of the ${title} Hutson location`,
      renderItem: _renderImg,
      renderThumbInner: _renderImgThumb,
    }
  })

  // Get shop links
  const shopLinks = getShopLinks(data, title)

  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => {
      promos.push(node)
    })
  }

  const meta = {
    title: `STIHL dealer in ${title} | Hutson Inc`,
    description: `Hutson is a STIHL dealer in ${title} and offers a full line of high-quality STIHL outdoor power tools, including: chainsaws, trimmers, pressure washers, blowers, edgers, and so much more.`,
  }

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Locations',
      link: '/locations/',
    },
    {
      name: title,
      link: slug,
    },
    {
      name: 'STIHL',
      link: `${pathname}/`,
    },
  ]

  return {
    breadcrumbs,
    data: sanityLocation,
    images: carouselImages,
    meta,
    promos,
    shopLinks,
    title,
  }
}

const getShopLinks = (data, title) => {
  const append = `in ${title}`
  return [
    {
      image: data['chainsawImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Chainsaws',
      description: `Shop STIHL chainsaws at Hutson ${append}`,
      link: '/stihl/chain-saws/',
      linkText: 'Shop',
    },
    {
      image: data['trimmerImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Trimmers & Brushcutters',
      description: `Shop STIHL trimmers & brushcutters at Hutson ${append}`,
      link: '/stihl/trimmers-and-brushcutters/',
      linkText: 'Shop',
    },
    {
      image: data['hedgeTrimmerImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Hedge Trimmers',
      description: `Shop STIHL hedge trimmers at Hutson ${append}`,
      link: '/stihl/hedge-trimmers/',
      linkText: 'Shop',
    },
    {
      image: data['blowerImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Blowers & Shredder Vacs',
      description: `Shop STIHL blowers & shredder vacs at Hutson ${append}`,
      link: '/stihl/blowers-and-shredder-vacs/',
      linkText: 'Shop',
    },
    {
      image: data['pressureWasherImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Pressure Washers',
      description: `Shop STIHL pressure washers at Hutson ${append}`,
      link: '/stihl/pressure-washers/',
      linkText: 'Shop',
    },
    {
      image: data['lawnMowerImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Lawn Mowers',
      description: `Shop STIHL lawn mowers at Hutson ${append}`,
      link: '/stihl/lawn-mowers/',
      linkText: 'Shop',
    },
    {
      image: data['polePrunerImage'].childImageSharp.gatsbyImageData,
      title: 'STIHL Pole Pruners',
      description: `Shop STIHL pole pruners at Hutson ${append}`,
      link: '/stihl/pole-pruners/',
      linkText: 'Shop',
    },
    {
      image: data['allCategoriesImage'].childImageSharp.gatsbyImageData,
      title: 'All STIHL Categories',
      description: `View all STIHL equipment at Hutson ${append}`,
      link: '/stihl/categories/',
      linkText: 'View',
    },
  ]
}

export const pageQuery = graphql`
  query locationStihlQuery($id: String!) {
    sanityLocation(id: { eq: $id }) {
      address
      city
      directionsLink
      hours {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
      }
      images {
        asset {
          gatsbyImageData(width: 680)
        }
      }
      imagesThumbnails: images {
        asset {
          gatsbyImageData(layout: FIXED, width: 92)
        }
      }
      locationNumber
      partsPhoneNumber
      phoneNumber
      servicePhoneNumber
      showPartsPhoneNumber
      showServicePhoneNumber
      slug
      specialHours {
        date
        hours
        title
      }
      state
      stihlDescriptionHtml
      title
      zip
    }
    allHutsonPromotion(
      filter: { tags: { in: ["stihl"] } }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    chainsawImage: file(relativePath: { eq: "locations/stihl/stihl-chainsaw.jpg" }) {
      ...SharpImage600
    }
    trimmerImage: file(relativePath: { eq: "locations/stihl/stihl-trimmer.jpg" }) {
      ...SharpImage600
    }
    hedgeTrimmerImage: file(relativePath: { eq: "locations/stihl/stihl-hedge-trimmer.jpg" }) {
      ...SharpImage600
    }
    blowerImage: file(relativePath: { eq: "locations/stihl/stihl-backpack-blower.jpg" }) {
      ...SharpImage600
    }
    pressureWasherImage: file(relativePath: { eq: "locations/stihl/stihl-pressure-washer.jpg" }) {
      ...SharpImage600
    }
    lawnMowerImage: file(relativePath: { eq: "locations/stihl/stihl-lawn-mower.jpg" }) {
      ...SharpImage600
    }
    polePrunerImage: file(relativePath: { eq: "locations/stihl/stihl-pole-pruner.jpg" }) {
      ...SharpImage600
    }
    allCategoriesImage: file(relativePath: { eq: "locations/stihl/stihl-all-categories.jpg" }) {
      ...SharpImage600
    }
  }
`

export default LocationStihlTemplate
