import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/button/button'
import Breadcrumbs from 'components/breadcrumbs'
import ImageGallery from 'react-image-gallery'
import ImageGalleryStyle from 'components/image-gallery/style.js'
import formatHolidayHours from 'utils/location-hours/format-holiday-hours'
import sortLocationHours from 'utils/location-hours/sort-location-hours'
import scrollTo from 'utils/scroll-to'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const LocationTopFold = ({
  address,
  breadcrumbs,
  city,
  contactFormLink,
  directionsLink,
  hours,
  specialHours = [],
  images,
  partsPhoneNumber,
  phoneNumber,
  servicePhoneNumber,
  state,
  title,
  zip,
}) => {
  const [storeHours, setStoreHours] = useState([])

  useEffect(() => {
    // Format hours and check for holidays
    let formattedHours = sortLocationHours(hours)
    if (specialHours.length) {
      formattedHours = formatHolidayHours(formattedHours, specialHours)
    }
    setStoreHours(formattedHours)
  }, [hours, specialHours])

  const hasAfterHoursNumber = partsPhoneNumber || servicePhoneNumber
  const sameAfterHoursNumber = partsPhoneNumber === servicePhoneNumber
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Main>
        <ImageGalleryContainer>
          <ImageGallery
            items={images}
            infinite={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={false}
            showIndex={images.length > 1}
            showThumbnails={images.length > 1}
            onThumbnailClick={(e, i) => {
              e.preventDefault()
            }}
          />
        </ImageGalleryContainer>
        <MainDetails>
          <Title>{title}</Title>
          <Overview>
            <Address>
              <OutboundLink href={directionsLink}>
                {address}
                <br />
                {city}, {state} {zip}
              </OutboundLink>
            </Address>
            <HoursContainer>
              <table className='hoursTable'>
                <tbody>
                  {storeHours.map((obj, key) => (
                    <tr key={`hour${key}`}>
                      <td>{obj.day}</td>
                      <td>
                        {obj.hours}
                        {obj.title && <span>({obj.title})</span>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </HoursContainer>
            <Button as={CallButtonLink} href={`tel:${phoneNumber}`} color='yellow'>
              Call Us: {phoneNumber}
            </Button>
            {contactFormLink && (
              <ContactLinks>
                <a href={contactFormLink} onClick={scrollTo}>
                  Fill out a contact form
                </a>
              </ContactLinks>
            )}
            {hasAfterHoursNumber ? (
              <>
                <hr />
                <AfterHoursLabel>Need after-hours support? Call or text us:</AfterHoursLabel>
                <PhoneNumbersContainer>
                  {sameAfterHoursNumber ? (
                    <span>
                      After-Hours Parts &amp; Service:&nbsp;
                      <a href={`tel:${partsPhoneNumber}`}>{partsPhoneNumber}</a>
                    </span>
                  ) : (
                    <>
                      {partsPhoneNumber && (
                        <span>
                          After-Hours Parts:&nbsp;
                          <a href={`tel:${partsPhoneNumber}`}>{partsPhoneNumber}</a>
                        </span>
                      )}
                      {servicePhoneNumber && (
                        <span>
                          After-Hours Service:&nbsp;
                          <a href={`tel:${servicePhoneNumber}`}>{servicePhoneNumber}</a>
                        </span>
                      )}
                    </>
                  )}
                </PhoneNumbersContainer>
              </>
            ) : null}
          </Overview>
        </MainDetails>
      </Main>
      <ImageGalleryStyle />
    </>
  )
}

const Main = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const ImageGalleryContainer = styled.div`
  @media (min-width: 800px) {
    ${column('3/5')}
  }
`

const MainDetails = styled.div`
  margin-top: 30px;

  hr {
    background-color: #d7d7d7;
    border: none;
    color: #efefef;
    height: 1px;
    margin: 20px auto;
    width: 100%;
  }

  @media (min-width: 800px) {
    ${column('2/5')}
    margin-top: 0;
  }
`

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const Overview = styled.div`
  font-size: 1.1em;
  margin: 5px auto 15px;

  @media (min-width: 800px) {
    font-size: 1em;
  }
`

const ContactLinks = styled.div`
  a {
    color: #377539;
    font-size: 1.1em;
    font-weight: 600;
    text-decoration: underline;
  }
`

const Address = styled.div`
  a {
    color: #377539;
    font-size: 1.05em;
    line-height: 1.45;
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
`

const HoursContainer = styled.div`
  margin-top: 10px;

  .hoursTable {
    border: none;
    text-transform: capitalize;

    tr {
      :first-of-type {
        font-weight: 700;
      }
    }

    td {
      padding: 2px 0;

      :last-child {
        padding-left: 15px;
      }

      span {
        font-weight: 400;
        padding-left: 5px;
      }
    }
  }
`

const CallButtonLink = styled.a`
  display: inline-block;
  margin: 15px 0;
  text-decoration: none;
`

const AfterHoursLabel = styled.p`
  font-weight: 700;
  margin: 0;
`

const PhoneNumbersContainer = styled.div`
  span {
    display: block;
    font-size: 1em;
    padding: 8px 0 0;
  }

  a {
    color: #377539;
    font-weight: 400;
    text-decoration: none;
    white-space: nowrap;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export default LocationTopFold
